import api from "@/core/services/api.service.js";
import {
  setTokenBridge,
  setTokenSatuSehat,
  setRefreshTokenBridge,
  setSsOrganizationId,
  setSsLocationDefault,
  getConfiguration,
  getSsSecret,
  getSsId
} from "@/core/services/jwt.service.js";
import {
  UPDATE_SS_CLIENT_ID,
  UPDATE_SS_CLIENT_SECRET,
  UPDATE_SS_ORGANIZATION_ID,
  UPDATE_SS_ACCESS_TOKEN,
} from "@/core/services/store/tokenstore.module";
api.setHeaderBridge();
var isAlert = false;

// Login To API Bridge & Satu Sehat
let login_data_api_bridge = {
  username: "medhop@wellmagic.id",
  password: "wm547u53h47",
  grant_type: "password",
  scope: "*",
  satu_sehat_clinic_id: getConfiguration().satu_sehat_clinic_id,
  ss_client_id: getSsId(),
  ss_client_secret: getSsSecret()
};

const CrudModuleBridge = {
  async submit(data, route) {
    let state = "",
      validationError = "",
      success = "";
    // SwalLoading
    SwalLoading.fire();
    await api.bridge
      .post(route, data)
      .then((response) => {

        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else if (response.status == 500) {
          Swal.fire(
            "Gagal",
            "Terjadi Kesalahan Pada Sistem, Silahkan Hubungi Tim Wellmagic",
            "error"
          );
        } else if (response.data.hasOwnProperty("issue")) {
          Swal.fire(
            "Gagal",
            "",
            "error"
          );
          state = "success";
          let successObj = {
            title: "error",
            message: response.data.issue[0].details.text,
          }
          success = successObj
          api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
            let responseData = response.data
            this.successGetTokenBridge(responseData)
            this.submit(data, route)
          })
        } else {
          state = "success";
          success = response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        } else if (error.response.status == 401) {
          api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
            let responseData = response.data
            this.successGetTokenBridge(responseData)
            this.submit(data, route)
          })
        }
      })
      .finally(() => {
        // Close SwalLoading
        SwalLoading.close();
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },

  successGetTokenBridge(data) {
    setTokenBridge(data.bridge.access_token)
    setRefreshTokenBridge(data.bridge.refresh_token)
    setTokenSatuSehat(data.satusehat.access_token)
    this.$store.dispatch(UPDATE_SS_ACCESS_TOKEN, data.satusehat.access_token);
    api.setHeaderBridge()
  },

  async submitWithoutAlert(data, route) {
    let state = "",
      validationError = "",
      success = "";
    await api.bridge
      .post(route, data)
      .then((response) => {

        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else if (response.status == 500) {
          Swal.fire(
            "Gagal",
            "Terjadi Kesalahan Pada Sistem, Silahkan Hubungi Tim Wellmagic",
            "error"
          );
        } else if (response.data.hasOwnProperty("issue")) {
          Swal.fire(
            "Gagal",
            response.data.issue[0].details.text,
            "error"
          );
          state = "success";
          let successObj = {
            title: "error",
            message: response.data.issue[0].details.text,
          }
          success = successObj
        } else {
          state = "success";
          success = response.data;
        }
      })
      .catch((error) => {
        if (error.response.status == 422) {
          state = "error";
          validationError = error.response.data;
        }
      })
      .finally(() => {
      });
    return {
      state: state,
      error: validationError,
      success: success,
    };
  },

  async getNoPgination(route, slug = "") {
    let data = {},
      meta = {};
    await api.bridge
      .get(route, slug)
      .then((response) => {
        if (response.status == 401 && isAlert == false) {
          isAlert = true;
          Swal.fire(
            "Gagal",
            "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
            "error"
          );
        } else {
          data = response.data.data;
        }
      })
      .catch((error) => {
       //  console.log("error", error);
      });
    return { data: data };
  },


  async get(route, params = "") {
    let data = null;
    await api.bridge
      .get(route, params)
      .then((response) => {
        if (response.status == 200) {
          data = response.data
        }
        // If Data Exist
        // if (response.status != 204 && response.status != 401) {
        //   data = response.data.data;
        //   for (let a = 0; a < data.length; a++) {
        //     data[a].number = a + 1;
        //   }
        // } else if (response.status == 401 && isAlert == false) {
        //   isAlert = true;
        //   Swal.fire(
        //     "Gagal",
        //     "Akun yang anda gunakan terdeteksi masuk di perangkat lain",
        //     "error"
        //   );
        // }
      })
      .catch((error) => {
        api.bridge.post("oauth/token", login_data_api_bridge).then((response) => {
          let responseData = response.data
          this.successGetTokenBridge(responseData)
          this.submit(data, route)
        })
      });
    return data;
  },





};

export default CrudModuleBridge;
